var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.license
    ? _c(
        "section",
        [
          _c(
            "b-form",
            { staticClass: "mt-2", attrs: { inline: "" } },
            [
              _c(
                "b-button",
                { staticClass: "no-border", on: { click: _vm.refreshList } },
                [_c("i", { staticClass: "fal fa-sync" })]
              ),
              _vm.license.multitenant
                ? _c(
                    "AddOrganisation",
                    {
                      key: _vm.addedOrganisationKey,
                      on: { invitedResult: _vm.refreshList },
                    },
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "inlineBtn",
                          attrs: { variant: "dark" },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("ADMINISTRATION.ADD_ORGANISATION")
                              ) +
                              "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "label",
                {
                  staticClass: "sr-only",
                  attrs: { for: "search-organisations" },
                },
                [_vm._v(_vm._s(_vm.$t("SEARCH")))]
              ),
              _c("b-form-input", {
                staticClass: "searchText hidden-mobile",
                attrs: {
                  trim: "",
                  id: "search-organisations",
                  placeholder: _vm.$t("SEARCH"),
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                  },
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
              _c(
                "label",
                {
                  staticClass: "sr-only",
                  attrs: { for: "inline-form-input-limit" },
                },
                [_vm._v(_vm._s(_vm.$t("LIMIT")))]
              ),
              _c(
                "b-form-select",
                {
                  staticClass: "limit hidden-mobile",
                  attrs: { name: "organisations_limit" },
                  model: {
                    value: _vm.limit,
                    callback: function ($$v) {
                      _vm.limit = $$v
                    },
                    expression: "limit",
                  },
                },
                [
                  _c("b-form-select-option", { attrs: { value: "10" } }, [
                    _vm._v("10"),
                  ]),
                  _c("b-form-select-option", { attrs: { value: "25" } }, [
                    _vm._v("25"),
                  ]),
                  _c("b-form-select-option", { attrs: { value: "45" } }, [
                    _vm._v("45"),
                  ]),
                  _c("b-form-select-option", { attrs: { value: "100" } }, [
                    _vm._v("100"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "hidden-not-mobile w-100 mt-2" },
                [
                  _c(
                    "b-form",
                    { attrs: { inline: "" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "search-organisations-mobile" },
                        },
                        [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                      ),
                      _c("b-form-input", {
                        staticClass: "ml-2 i-100 searchText",
                        attrs: {
                          trim: "",
                          id: "search-organisations-mobile",
                          placeholder: _vm.$t("SEARCH"),
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "list-limit-organisation-mobile" },
                        },
                        [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                      ),
                      _c(
                        "b-form-select",
                        {
                          staticClass: "limit",
                          attrs: {
                            id: "list-limit-organisation-mobile",
                            name: "meetings_limit",
                          },
                          model: {
                            value: _vm.limit,
                            callback: function ($$v) {
                              _vm.limit = $$v
                            },
                            expression: "limit",
                          },
                        },
                        [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: "10" } },
                            [_vm._v("10")]
                          ),
                          _c(
                            "b-form-select-option",
                            { attrs: { value: "25" } },
                            [_vm._v("25")]
                          ),
                          _c(
                            "b-form-select-option",
                            { attrs: { value: "45" } },
                            [_vm._v("45")]
                          ),
                          _c(
                            "b-form-select-option",
                            { attrs: { value: "100" } },
                            [_vm._v("100")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "b-dropdown",
                {
                  staticClass: "filter-dropdown",
                  attrs: {
                    id: "dropdown-1",
                    size: "sm",
                    text: _vm.filterTextType,
                    "toggle-class": _vm.filterClassType,
                  },
                },
                [
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "sm",
                            name: "check-button",
                            value: "0",
                          },
                          model: {
                            value: _vm.filter_organisation_type,
                            callback: function ($$v) {
                              _vm.filter_organisation_type = $$v
                            },
                            expression: "filter_organisation_type",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.ORGANISATION_TYPE.0")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "sm",
                            name: "check-button",
                            value: "1",
                          },
                          model: {
                            value: _vm.filter_organisation_type,
                            callback: function ($$v) {
                              _vm.filter_organisation_type = $$v
                            },
                            expression: "filter_organisation_type",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.ORGANISATION_TYPE.1")))]
                      ),
                    ],
                    1
                  ),
                  _vm.filter_organisation_type.length != 0
                    ? _c("b-dropdown-divider")
                    : _vm._e(),
                  _vm.filter_organisation_type.length != 0
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.clearFilterType } },
                        [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR_SELECTED")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-dropdown",
                {
                  staticClass: "filter-dropdown",
                  attrs: {
                    id: "dropdown-1",
                    size: "sm",
                    text: _vm.filterTextStatus,
                    "toggle-class": _vm.filterClassStatus,
                  },
                },
                [
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "sm",
                            name: "check-button",
                            value: "0",
                          },
                          model: {
                            value: _vm.filter_organisation_status,
                            callback: function ($$v) {
                              _vm.filter_organisation_status = $$v
                            },
                            expression: "filter_organisation_status",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.ORGANISATION_STATUS.0")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "sm",
                            name: "check-button",
                            value: "1",
                          },
                          model: {
                            value: _vm.filter_organisation_status,
                            callback: function ($$v) {
                              _vm.filter_organisation_status = $$v
                            },
                            expression: "filter_organisation_status",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.ORGANISATION_STATUS.1")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "sm",
                            name: "check-button",
                            value: "2",
                          },
                          model: {
                            value: _vm.filter_organisation_status,
                            callback: function ($$v) {
                              _vm.filter_organisation_status = $$v
                            },
                            expression: "filter_organisation_status",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("FILTER.ORGANISATION_STATUS.2")))]
                      ),
                    ],
                    1
                  ),
                  _vm.filter_organisation_status.length != 0
                    ? _c("b-dropdown-divider")
                    : _vm._e(),
                  _vm.filter_organisation_status.length != 0
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.clearFilterStatus } },
                        [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR_SELECTED")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.showFilterClearAll
                ? _c(
                    "b-button",
                    {
                      staticClass: "no-border",
                      attrs: { size: "sm" },
                      on: { click: _vm.clearFilterAll },
                    },
                    [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR")))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c("b-table", {
                attrs: {
                  bordered: "",
                  small: "",
                  items: _vm.items,
                  hover: "",
                  fields: _vm.fields,
                  busy: _vm.loading,
                },
                on: { "row-clicked": _vm.clickedRow },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(name)",
                      fn: function (data) {
                        return [
                          _c("b-avatar", {
                            staticClass: "profileImage",
                            attrs: {
                              src: _vm.organisationImage(data.item),
                              alt: "placeholder",
                              variant: "light",
                            },
                          }),
                          _vm._v(
                            "\n        " + _vm._s(data.item.name) + "\n        "
                          ),
                          data.item.invite == 1
                            ? _c("b-badge", { attrs: { variant: "info" } }, [
                                _vm._v(_vm._s(_vm.$t("STATUS_INVITED"))),
                              ])
                            : _vm._e(),
                          data.item.state == 0
                            ? _c("b-badge", { attrs: { variant: "danger" } }, [
                                _vm._v(_vm._s(_vm.$t("STATUS_INACTIVE"))),
                              ])
                            : _vm._e(),
                          data.item.sefos_node == 1
                            ? _c("b-badge", { attrs: { variant: "primary" } }, [
                                _vm._v(_vm._s(_vm.$t("STATUS_SEFOS"))),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(current_storage)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.bytesToSize(data.item.current_storage))
                          ),
                          data.item.total_storage != 0
                            ? _c("span", [
                                _vm._v(
                                  "\n          / " +
                                    _vm._s(
                                      _vm.bytesToSize(data.item.total_storage)
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(id)",
                      fn: function (data) {
                        return [
                          _c("b-img", {
                            staticClass: "profileImage",
                            attrs: {
                              src: _vm.organisationImage(data.item),
                              alt: data.item.name,
                              rounded: "circle",
                            },
                          }),
                          _vm._v(
                            "\n        " + _vm._s(data.item.name) + "\n        "
                          ),
                          data.item.invite == 1
                            ? _c("b-badge", { attrs: { variant: "info" } }, [
                                _vm._v(_vm._s(_vm.$t("STATUS_INVITED"))),
                              ])
                            : _vm._e(),
                          data.item.state == 0
                            ? _c("b-badge", { attrs: { variant: "danger" } }, [
                                _vm._v(_vm._s(_vm.$t("STATUS_INACTIVE"))),
                              ])
                            : _vm._e(),
                          data.item.sefos_node == 1
                            ? _c("b-badge", { attrs: { variant: "primary" } }, [
                                _vm._v(_vm._s(_vm.$t("STATUS_SEFOS"))),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "table-busy",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center my-2" },
                            [_c("b-spinner", { staticClass: "align-middle" })],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1235480176
                ),
              }),
              _c("b-pagination", {
                staticClass: "mt-2",
                attrs: {
                  "total-rows": _vm.totalItems,
                  "per-page": _vm.limit,
                  align: "center",
                  pills: "",
                },
                on: { input: _vm.getOrganisations },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }