<template>
  <section v-if="license">
    <b-form inline class="mt-2">
      <b-button @click="refreshList" class="no-border"
        ><i class="fal fa-sync"></i
      ></b-button>
      <AddOrganisation
        @invitedResult="refreshList"
        :key="addedOrganisationKey"
        v-if="license.multitenant"
      >
        <b-btn class="inlineBtn" variant="dark">
          {{ $t("ADMINISTRATION.ADD_ORGANISATION") }}
        </b-btn>
      </AddOrganisation>
    
      <label class="sr-only" for="search-organisations">{{$t('SEARCH')}}</label>
      <b-form-input trim
        id="search-organisations"
        class="searchText hidden-mobile"
        v-model="searchText"
        :placeholder="$t('SEARCH')"
        @keydown.enter.prevent
      ></b-form-input>
      <label class="sr-only" for="inline-form-input-limit">{{$t('LIMIT')}}</label>
      <b-form-select  name="organisations_limit" v-model="limit" class="limit hidden-mobile">
        <b-form-select-option value="10">10</b-form-select-option>
        <b-form-select-option value="25">25</b-form-select-option>
        <b-form-select-option value="45">45</b-form-select-option>
        <b-form-select-option value="100">100</b-form-select-option>
      </b-form-select>

      
      <div class="hidden-not-mobile w-100 mt-2">
        <b-form inline>
          <label class="sr-only" for="search-organisations-mobile">{{$t('SEARCH')}}</label>
          <b-form-input trim
            id="search-organisations-mobile"
            class="ml-2 i-100 searchText"
            v-model="searchText"
            :placeholder="$t('SEARCH')"
            @keydown.enter.prevent
          ></b-form-input>
          <label class="sr-only" for="list-limit-organisation-mobile">{{$t('LIMIT')}}</label>
          <b-form-select id="list-limit-organisation-mobile" name="meetings_limit"  v-model="limit" class="limit">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="25">25</b-form-select-option>
            <b-form-select-option value="45">45</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-form>
      </div>

    </b-form>

    <div class="mt-2">
      <b-dropdown id="dropdown-1" class="filter-dropdown" size="sm" :text="filterTextType" :toggle-class="filterClassType">
        <b-dropdown-form>
          <b-form-checkbox size="sm" v-model="filter_organisation_type" name="check-button" value="0">{{ $t('FILTER.ORGANISATION_TYPE.0') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form>
          <b-form-checkbox size="sm" v-model="filter_organisation_type" name="check-button" value="1">{{ $t('FILTER.ORGANISATION_TYPE.1') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-divider v-if="filter_organisation_type.length != 0"></b-dropdown-divider>
        <b-dropdown-item v-if="filter_organisation_type.length != 0" @click="clearFilterType">{{ $t('FILTER.CLEAR_SELECTED') }}</b-dropdown-item>
      </b-dropdown>

      <b-dropdown id="dropdown-1" class="filter-dropdown" size="sm" :text="filterTextStatus" :toggle-class="filterClassStatus">
         <b-dropdown-form>
          <b-form-checkbox size="sm" v-model="filter_organisation_status" name="check-button" value="0">{{ $t('FILTER.ORGANISATION_STATUS.0') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form>
          <b-form-checkbox size="sm" v-model="filter_organisation_status" name="check-button" value="1">{{ $t('FILTER.ORGANISATION_STATUS.1') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form>
          <b-form-checkbox size="sm" v-model="filter_organisation_status" name="check-button" value="2">{{ $t('FILTER.ORGANISATION_STATUS.2') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-divider v-if="filter_organisation_status.length != 0"></b-dropdown-divider>
        <b-dropdown-item v-if="filter_organisation_status.length != 0" @click="clearFilterStatus">{{ $t('FILTER.CLEAR_SELECTED') }}</b-dropdown-item>
      </b-dropdown>

      <b-button class="no-border" size="sm" v-if="showFilterClearAll" @click="clearFilterAll">{{ $t('FILTER.CLEAR') }}</b-button>
      </div>

    <div class="mt-2">
      <b-table
        bordered
        small
        :items="items"
        hover
        :fields="fields"
        :busy="loading"
        @row-clicked="clickedRow"
      >
        <template v-slot:cell(name)="data">
          <b-avatar
            class="profileImage"
            :src="organisationImage(data.item)"
            alt="placeholder"
            variant="light"
          ></b-avatar>
          {{ data.item.name }}
          <b-badge variant="info" v-if="data.item.invite == 1">{{$t('STATUS_INVITED')}}</b-badge>
          <b-badge variant="danger" v-if="data.item.state == 0">{{$t('STATUS_INACTIVE')}}</b-badge>
          <b-badge variant="primary" v-if="data.item.sefos_node == 1">{{$t('STATUS_SEFOS')}}</b-badge>
        </template>

        <template v-slot:cell(current_storage)="data">
          {{ bytesToSize(data.item.current_storage)
          }}<span v-if="data.item.total_storage != 0">
            / {{ bytesToSize(data.item.total_storage) }}</span
          >
        </template>

        <template v-slot:cell(id)="data">
          <b-img
            class="profileImage"
            :src="organisationImage(data.item)"
            :alt="data.item.name"
            rounded="circle"
          ></b-img>
          {{ data.item.name }}
          <b-badge variant="info" v-if="data.item.invite == 1">{{$t('STATUS_INVITED')}}</b-badge>
          <b-badge variant="danger" v-if="data.item.state == 0">{{$t('STATUS_INACTIVE')}}</b-badge>
          <b-badge variant="primary" v-if="data.item.sefos_node == 1">{{$t('STATUS_SEFOS')}}</b-badge>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="limit"
        align="center"
        @input="getOrganisations"
        pills
      >
      </b-pagination>
    </div>
  </section>
</template>
<script>
import AddOrganisation from "@/components/Administration/AddOrganisation";
export default {
  props: ["license"],
  components: { AddOrganisation },
  data() {
    return {
      addedOrganisationKey: 0,
      filter_organisation_type: [],
      filter_organisation_status: [],
      keyCount: 0,
      limit: 25,
      options: [
        { text: this.$t("ALL"), value: "" },
        { text: this.$t("AS_SERVICE"), value: "as-service" },
        { text: this.$t("STATUS_SEFOS"), value: "node" },
        { text: this.$t("ACTIVE"), value: "active" },
        { text: this.$t("INACTIVE"), value: "inactive" },
        { text: this.$t("INVITED"), value: "invited" }
      ],
      organisation_type: "",
      searchText: "",
      loading: false,
      currentPage: 1,
      sefos_node: false,
      sefos_nodes: [
        { text: this.$t("YES"), value: 1 },
        { text: this.$t("NO"), value: 0 },
      ],
      items: [],
      totalItems: 0,
      state: 1,
      fields: [
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          key: "name",
          label: this.$t("NAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "unique_identifier",
          label: this.$t("ORGANISATION.UNIQUE_IDENTIFIER"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "current_storage",
          label: this.$t("STORAGE"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
      ]
    };
  },
  methods: {
    clearFilterType()
    {
      this.filter_organisation_type = [];
    },
    clearFilterStatus()
    {
      this.filter_organisation_status = [];
    },
    clearFilterAll(){
      this.filter_organisation_type = [];
      this.filter_organisation_status = [];
    },
    refreshList: function() {
      this.addedOrganisationKey = this.addedOrganisationKey + 1;
      this.keyCount = this.keyCount + 1;
      this.getOrganisations();
    },
    organisationImage(item) {
      if (item.image_uuid) {
        return (
          this.user.hostname + "/organisation/image/" + item.id + "?" + item.image_uuid
        );
      } else {
        return "/img/organisation.png";
      }
    },
    changeList() {
      this.currentPage = 1;
      this.getOrganisations();
    },
    clickedRow(data) {
      this.$router.push({
        name: "Administration.Organisation.Information",
        params: { organisation_id: data.id },
      }).catch(() => {});
    },
    showState: function(state) {
      return state === 0 ? this.$t("ACTIVE") : this.$t("INACTIVE");
    },
    getOrganisations: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(
          this.user.hostname + "/administration/organisations/list",
          {
            page: this.currentPage,
            limit: parseInt(this.limit),
            type: this.organisation_type,
            search_text: this.searchText,
            filter_organisation_type: this.filter_organisation_type,
            filter_organisation_status: this.filter_organisation_status
          }
        )
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  computed: {
    showFilterClearAll(){
      if(this.filter_organisation_type.length != 0)
      {
        return true;
      }
      if(this.filter_organisation_status.length != 0)
      {
        return true;
      }
      return false;
    },
    filterClassType() {
      if(this.filter_organisation_type.length != 0)
      {
        return  ['filter-selected','no-border'];
      }else{
        return ['no-border'];
      }
    },
    filterClassStatus() {
      if(this.filter_organisation_status.length != 0)
      {
        return  ['filter-selected','no-border'];
      }else{
        return ['no-border'];
      }
    },
    filterTextType() {
      if(this.filter_organisation_type.length != 0)
      {
        if(this.filter_organisation_type.length > 1)
        {
          return this.$t('FILTER.ORGANISATION_TYPE.TITLE') + ": " + this.$t('FILTER.ORGANISATION_TYPE.' + this.filter_organisation_type.slice().sort()[0]) + " +(" + (this.filter_organisation_type.length-1) + ")";
        }else{
          return this.$t('FILTER.ORGANISATION_TYPE.TITLE') + ": " + this.$t('FILTER.ORGANISATION_TYPE.' + this.filter_organisation_type.slice().sort()[0]);
        }
      }else{
         return this.$t('FILTER.ORGANISATION_TYPE.TITLE');
      }
    },
    filterTextStatus() {
     
      if(this.filter_organisation_status.length != 0)
      {
        if(this.filter_organisation_status.length > 1)
        {
          return this.$t('FILTER.STATUS.TITLE') + ": " + this.$t('FILTER.ORGANISATION_STATUS.' + this.filter_organisation_status.slice().sort()[0]) + " +(" + (this.filter_organisation_status.length-1) + ")";
        }else{
          return this.$t('FILTER.STATUS.TITLE') + ": " + this.$t('FILTER.ORGANISATION_STATUS.' + this.filter_organisation_status.slice().sort()[0]);
        }
      }else{
         return this.$t('FILTER.STATUS.TITLE');
      }
    }
  },
  watch: {
    filter_organisation_type: function(new_array)
    {
        localStorage.setItem("list_organisations_type", JSON.stringify(new_array));
        this.getOrganisations();
    },
    filter_organisation_status: function(new_array)
    {
        localStorage.setItem("list_organisations_status", JSON.stringify(new_array));
        this.getOrganisations();
    },
    searchText: function() {
      if (this.searchText.length == 0) {
        this.getOrganisations();
      }
      if (this.searchText.length >= 2) {
        this.getOrganisations();
      }
    },
    limit: function() {
      this.getOrganisations();
    },
  },
  mounted: function() {
    if (localStorage.getItem("list_organisations_type") !== null) {
      this.filter_organisation_type = JSON.parse(localStorage.getItem("list_organisations_type"));
    }
    if (localStorage.getItem("list_organisations_status") !== null) {
      this.filter_organisation_status = JSON.parse(localStorage.getItem("list_organisations_status"));
    }
    this.getOrganisations();
  },
};
</script>
<style></style>
